import request from "./request";

export function getPromoters(params) {
  return request({
    url: "admin/users",
    method: "get",
    params,
  });
}

export function createPromoter(data) {
  return request({
    url: "admin/users",
    method: "post",
    data,
  });
}

export function editPromoter(id, data) {
  return request({
    url: `/admin/users/${id}`,
    method: "put",
    data,
  });
}

export function getUser(id) {
  return request({
    url: `/users/${id}`,
    method: "get",
  });
}

// export function adminExportExcel() {
//   return request({
//     url: '/admin/users/_download',
//     method:'get'
//   })
// }
