import request from "./request";

export function getCustomers(params) {
  return request({
    url: "/promoter/customers",
    method: "get",
    params,
  });
}

export function updateAuth(data) {
  return request({
    url: "/customers/_update_auth",
    method: "post",
    data,
  });
}

export function addRecord(data) {
  return request({
    url: "/promoter/customers/_add_record",
    method: "post",
    data
  })
}

export function sendVerify(params) {
  return request({
    url: '/customers/verify_code',
    method: 'get',
    params
  })
}
