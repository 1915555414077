import persistedState from "vuex-persistedstate";
import { createStore } from "vuex";
export default createStore({
  state: {
    accessToken: null,
    roles: [],
    isRegistered: false,
    form: { name: "", phone: "" },
    policeOffice: {
      name: "温州市公安局洞头分局",
    },
    url:null
  },
  mutations: {
    setAccessToken(state, accessToken) {
      state.accessToken = accessToken;
    },
    setUrl(state, url) {
      state.url = url;
    },
    setRoles(state, roles) {
      state.roles = roles;
    },
    setRegister(state, isRegistered) {
      state.isRegistered = isRegistered;
    },
    setForm(state, form) {
      state.form = form;
    },
    setPoliceOffice(state, data) {
      state.policeOffice = data;
    },
  },
  getters: {
    accessToken(state) {
      return state.accessToken;
    },
    roles(state) {
      return state.roles;
    },
    isRegistered(state) {
      return state.isRegistered;
    },
    form(state) {
      return state.form;
    },
    policeOffice(state) {
      return state.policeOffice;
    },
    url(state) {
      return state.url
    }
  },
  actions: {},
  modules: {},
  plugins: [persistedState()],
});
