import request from "./request";

export function getPoliceOffices(params) {
  return request({
    url: "super_admin/police_offices",
    method: "get",
    params,
  });
}

export function getPromotersOfOffice(id) {
  return request({
    url: "super_admin/police_offices/" + id,
    method: "get",
  });
}

/**
 * 导出各个警务室的推广业绩
 * @returns
 */
export function exportOfficeStats() {
  return request({
    url: "/super_admin/police_offices/_download",
    method: "get",
  });
}
