import request from './request';
export function getPromoterDetail() {
    return request({
        url: '/promoter/users',
        method: 'get',
     })
}

export function addCustomers(data) {
    return request({
        url: '/customers',
        method: 'post',
        data
    })
}