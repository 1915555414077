import { getAliOSSCred } from "@/apis/upload.js";
import OSS from "ali-oss";

export function upload(file) {
  return getAliOSSCred().then((res) => {
    let cred = res.data;
    const client = new OSS({
      region: "oss-cn-hangzhou",
      accessKeyId: cred.accessKeyId,
      accessKeySecret: cred.accessKeySecret,
      stsToken: cred.securityToken,
      bucket: cred.bucket,
    });
    //TODO需要修改base_url
    const split = file.file.name.split(".");
    const ext = split[split.length - 1];
    let key = `uploadFileTest/${Date.now()}.${ext}`; //
    return client.put(key, file.file);
  });
}
export default upload;
