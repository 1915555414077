<template>
  <div class="page">
    <div class="head">
      <div class="title">
        <img src="@/assets/images/office-label.png" alt class="label" />
      </div>
      <div class="img-wrap">
        <img
          v-if="ifCert"
          class="plus"
          style="padding-right: 10px"
          src="@/assets/images/download.svg"
          alt
          @click="handleDownload"
        />
        <img class="plus" src="@/assets/images/Qcode.svg" @click="getHome" />
      </div>
    </div>
    <div class="offices">
      <div class="office sum">
        <div class="info">
          <div class="name">
            <span>汇总</span>
          </div>
        </div>
        <div class="count-total">
          <div class="number">{{ totalAmount }}</div>
          <div class="label">推广总计</div>
        </div>
        <div class="count-today" v-if="ifCert">
          <div class="number">{{ totalAuth }}</div>
          <div class="label">实名总计</div>
        </div>
        <div class="count-today">
          <div class="number">{{ todayAmount }}</div>
          <div class="label">今日推广</div>
        </div>
        <div class="count-today" v-if="ifCert">
          <div class="number">{{ todayAuth }}</div>
          <div class="label">今日实名</div>
        </div>
      </div>
      <div class="office" v-for="office in offices" :key="office.id" @click="goto(office)">
        <div class="info">
          <div class="name">
            <span>{{ office.name }}</span>
          </div>
        </div>
        <div class="count-total">
          <div class="number">{{ office.totalAmount }}</div>
          <div class="label">推广总计</div>
        </div>
        <div class="count-today" v-if="ifCert">
          <div class="number">{{ office.totalAuth }}</div>
          <div class="label">实名总计</div>
        </div>
        <div class="count-today">
          <div class="number">{{ office.todayAmount }}</div>
          <div class="label">今日推广</div>
        </div>
        <div class="count-today" v-if="ifCert">
          <div class="number">{{ office.todayAuth }}</div>
          <div class="label">今日实名</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import { getPoliceOffices, exportOfficeStats } from "../../apis/super-admin";

export default {
  data() {
    return {
      offices: []
    };
  },
  created() {
    this.getPoliceOffices();
  },
  computed: {
    policeOffice() {
      return this.$store.state.policeOffice;
    },
    ifCert() {
      return this.policeOffice.ifCert;
    },

    // 总推广数
    totalAmount() {
      return this.offices.reduce((total, office) => {
        return total + office.totalAmount;
      }, 0);
    },

    // 今日推广数
    todayAmount() {
      return this.offices.reduce((total, office) => {
        return total + office.todayAmount;
      }, 0);
    },

    // 总实名数
    totalAuth() {
      return this.offices.reduce((total, office) => {
        return total + office.totalAuth;
      }, 0);
    },

    // 今日实名数
    todayAuth() {
      return this.offices.reduce((total, office) => {
        return total + office.todayAuth;
      }, 0);
    },
  },
  methods: {
    getPoliceOffices() {
      getPoliceOffices().then((res) => {
        this.offices = res.data;
      });
    },

    getHome() {
      this.$router.push({ name: "Home" });
    },

    goto(office) {
      this.$router.push({
        name: "Promoters",
        query: { id: office.id },
      });
    },
    handleDownload() {
      const roles = this.$store.getters.roles;
      if (roles.includes("ROLE_super_admin")) {
        Toast({ type: 'loading', message: "正在统计数据，稍等", duration: 4000 });
        exportOfficeStats().then((res) => {
          window.open(res.data + "#");
        });
      } else {
        Toast("没有权限");
      }
    },
  },
  components: {},
  props: {},
};
</script>
<style lang="less" scoped>
.page {
  background-color: rgb(16 38 139);
  height: 100vh;
  background-image: url(../../assets/images/admin-bg.jpg);
  background-size: cover;
  padding: 25px;
  box-sizing: border-box;
  overflow-y: scroll;
}

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  .title {
    .label {
      height: 25px;
      width: 120px;
      display: block;
    }
  }
}

.promoter-label {
  height: 24px;
  width: 88px;
}

.plus {
  width: 25px;
  height: 25px;
}
.offices {
  margin-top: 47px;
}
.office {
  color: #c2cff5;
  display: flex;
  border-bottom: 1px solid rgba(194, 207, 245, 0.37);
  padding: 15px 0;
  .info {
    width: 50%;
    display: flex;
    align-items: center;
    .name {
      font-size: 18px;
      font-weight: bold;
      line-height: 150%;
    }
  }
  .count-total {
    width: 25%;
    text-align: center;
  }
  .count-today {
    width: 25%;
    text-align: center;
  }
  .number {
    font-size: 17px;
    color: #9dff77;
    font-weight: bold;
    line-height: 150%;
  }

  .label {
    font-size: 13px;
    line-height: 150%;
  }
}
.sum {
  .info {
    display: flex;
    align-items: center;
  }
}

// Dialog
.masking {
  height: 100vh;
  width: 100vw;
  background-color: rgba(30, 35, 58, 0.93);
  position: absolute;
  top: 0;
  left: 0;
}
.dialog {
  width: 315px;
  height: 237px;
  background-image: url(../../assets/images/dialog-bg.svg);
  background-size: 100% 100%;
  position: relative;
  left: calc(50% - 157.5px);
  top: calc(50% - 137.5px);
  padding: 25px;
  box-sizing: border-box;

  .close {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 10px;
    height: 10px;
  }
  .title {
    font-size: 17px;
    color: #c2cff5;
    text-align: center;
  }
  .form-input {
    width: 100%;
    height: 35px;
    margin-top: 15px;
    border: 1.5px solid #20a3f5;
    background-color: rgba(16, 26, 57, 0.5);
    color: #c2cff5;
    font-size: 13px;
    padding-left: 15px;
    box-sizing: border-box;
    line-height: 35px;
  }

  .desc {
    color: #c2cff5;
    font-size: 12px;
    text-align: center;
    margin-top: 10px;
  }

  .submit {
    width: 100%;
    height: 35px;
    background-color: #20a3f5;
    border: none;
    color: white;
    font-size: 16px;
    border-radius: 2px;
    margin-top: 10px;
  }
}
</style>