<template>
  <div class="home">
    <div class="header">
      <img src="@/assets/images/title.png" alt class="title" />
    </div>
    <div class="body">
      <div class="info" @click="toggleVisible">
        <template v-if="visible">
          <span>
            {{ promoter.name }}：当前推广次数为
            <span class="number">{{ promoter.totalAmount }}</span>。
          </span>
          <span @click.stop="goAdmin">
            管理入口
            <img src="@/assets/images/arrow.svg" class="arrow" alt />
          </span>
        </template>
        <span v-else>{{ promoter.name }}邀请您下载按照国家防诈中心 App</span>
      </div>
      <div class="text">
        注意：请勿在单个设备重复刷取。
        <span v-if="visible">一经发现，推广成绩 一律作废</span>
      </div>
      <img src="@/assets/images/body-title.png" class="title" alt />
      <div class="box" id="qrcode" ref="box"></div>
      <!-- <div class="count-down">{{ count }}s后刷新</div> -->
    </div>
    <div class="footer">
      <img src="@/assets/images/icons.png" alt class="icons" />
      <div class="text">{{ policeOfficeName }}</div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { getPromoterDetail } from "@/apis/promoter";
import { Toast } from "vant";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      id: null,
      promoter: {
        totalAmount: "",
      },
      roles: [],
      visible: true,
    };
  },
  computed: {
    isAdmin() {
      return (
        this.roles.includes("ROLE_admin") ||
        this.roles.includes("ROLE_super_admin")
      );
    },

    policeOfficeName() {
      const policeOffice = this.$store.getters.policeOffice
      if (!policeOffice) return '温州市公安局';
      const { district } = policeOffice;
      if (!district) return '温州市公安局';
      if (district.indexOf('县')) {
        return district + "公安局";
      } else {
        return "温州市公安局" + district.substring(0, district.length - 1) + "分局"
      }
    }
  },
  created() {
    this.roles = this.$store.getters.roles;
  },
  mounted() {
    // 如果 Query 中有 id ，则取 query 中的 ID
    // 如果没有，则等 getPromoterDetail 获取到 id
    const id = this.$route.query.id;
    if (id) {
      this.id = id;
    }

    this.getPromoterDetail();
    this.qrcode = new QRCode(document.getElementById("qrcode"), {
      width: 140,
      height: 140,
      // useSVG: true,
    });
    // this.handleCountDown();
  },

  methods: {
    toggleVisible() {
      this.visible = !this.visible;
      Toast((this.visible ? "显示" : "隐藏") + "推广成绩");
    },
    // handleCountDown() {
    //   this.setInterval = setInterval(() => {
    //     if (this.count <= 0) {
    //       clearInterval(this.setInterval);
    //       setTimeout(() => {
    //         console.log("刷新二维码 :>> ");
    //         this.count = 20;
    //         this.handleCountDown();
    //       }, 1000);
    //     } else {
    //       this.count -= 1;
    //     }
    //   }, 1000);
    // },
    generateBarcode() {
      const baseURL = process.env.VUE_APP_API_QCODE;
      const url = `${baseURL}register?id=${this.id}`;
      //TODO text中的 url 需要被更换
      this.qrcode.clear();
      this.qrcode.makeCode(url);
    },
    getPromoterDetail() {
      getPromoterDetail().then((res) => {
        this.promoter = res.data;
        this.id = this.promoter.id;
      });
    },
    goAdmin() {
      if (this.roles.includes("ROLE_super_admin")) {
        this.$router.push({
          name: "Offices",
        });
      } else if (this.roles.includes("ROLE_admin")) {
        this.$router.push({
          name: "Promoters",
        });
      } else {
        this.$router.push({
          name: "Customers",
          query: {
            id: this.id,
          },
        });
      }
    },
  },
  watch: {
    id(val) {
      console.log("id", val);
      if (val) {
        this.generateBarcode();
      }
    },
  },
};
</script>
<style lang='less' scoped>
.count-down {
  margin-top: 7px;
  color: #fff;
  font-size: 10px;
}
.arrow {
  padding-left: 10px;
  width: 10px;
  height: 5px;
}
.home {
  text-align: center;
  width: 100vw;
  min-height: 667px;
  height: 100vh;
  background-color: rgb(16 38 139);
  background: url("../assets/images/bg.jpg");
  background-position: center;
  background-size: cover;
  position: relative;
  .header {
    position: relative;
    .title {
      margin-top: 15px;
      width: 283px;
      height: 150px;
      object-fit: cover;
    }
  }
  .body {
    margin-top: 25px;
    .info {
      font-size: 14px;
      color: #c2cff5;
      font-weight: bold;
      span.number {
        font-size: 16px;
        color: #9dff77;
      }
    }
    .text {
      margin: 0 auto;
      width: 276px;
      margin-top: 15px;
      font-size: 11px;
      font-weight: bold;
      color: #c2cff5;
    }
    .title {
      padding-top: 60px;
      width: 178px;
      height: 30px;
      object-fit: cover;
    }
    .box {
      margin: 35px auto 0;
      width: 168px;
      height: 168px;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .admin-entry {
      line-height: 200%;
      color: white;
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      margin-top: 10px;
    }
  }
  .footer {
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    bottom: 15px;
    .icons {
      margin: 50px auto 0;
      height: 35px;
      width: 258px;
      display: block;
    }
    .text {
      margin-top: 15px;
      font-weight: bold;
      color: #c2cff5;
      font-size: 13px;
    }
  }
}
</style>
