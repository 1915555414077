<template>
  <div class="page">
    <div class="head">
      <div class="title">
        <img src="@/assets/images/promoter-label.png" alt class="label" />
      </div>
      <div class="icon-wrap">
        <!-- <img
          v-if="ifCert && isSuperAdmin"
          class="plus"
          style="padding-right: 10px"
          src="@/assets/images/download.svg"
          @click="handleDownload"
        />-->
        <img class="plus" src="@/assets/images/Qcode.svg" @click="getHome" />
        <img
          style="margin-left: 10px"
          v-if="isOnlyAdmin"
          class="plus"
          src="../../assets/images/plus.svg"
          @click="showMasking"
        />
      </div>
    </div>
    <div class="promoters">
      <div class="promoter sum">
        <div class="info">
          <div class="name">
            <span>汇总</span>
          </div>
        </div>
        <div class="count-total">
          <div class="number">{{ totalAmount }}</div>
          <div class="label">推广总计</div>
        </div>
        <div class="count-today" v-if="ifCert">
          <div class="number">{{ totalAuth }}</div>
          <div class="label">实名统计</div>
        </div>
        <div class="count-today">
          <div class="number">{{ todayAmount }}</div>
          <div class="label">今日推广</div>
        </div>
        <div class="count-today" v-if="ifCert">
          <div class="number">{{ todayAuth }}</div>
          <div class="label">今日实名</div>
        </div>
      </div>
      <div
        class="promoter"
        v-for="promoter in promoters"
        :key="promoter.id"
        @click="handleCustomersRoute(promoter.id)"
      >
        <div class="info">
          <div class="name">
            <span>{{ promoter.name }}</span>
            <span @click.stop="selectPromoter(promoter)">
              <img
                v-if="isOnlyAdmin"
                src="@/assets/images/edit.svg"
                style="width: 12px; height: 12px; padding-left: 10px"
                alt
              />
            </span>
          </div>
          <div class="label">{{ promoter.phone }}</div>
        </div>
        <div class="count-total">
          <div class="number">{{ promoter.totalAmount }}</div>
          <div class="label">推广总计</div>
        </div>
        <div class="count-today" v-if="ifCert">
          <div class="number">{{ promoter.totalAuth }}</div>
          <div class="label">实名总计</div>
        </div>
        <div class="count-today">
          <div class="number">{{ promoter.todayAmount }}</div>
          <div class="label">今日推广</div>
        </div>
        <div class="count-today" v-if="ifCert">
          <div class="number">{{ promoter.todayAuth }}</div>
          <div class="label">今日实名</div>
        </div>
      </div>

      <div class="empty" v-if="promoters.length == 0">暂无推广员</div>
    </div>
    <div class="masking" v-show="maskingVisible">
      <div class="dialog" @click.stop>
        <img class="close" src="../../assets/images/close.svg" @click="closeMasking" />
        <div class="title">{{ currentPromoter ? "修改推广员信息" : "新增推广员" }}</div>
        <input class="form-input" name="name" placeholder="姓名" v-model="form.name" />
        <input class="form-input" name="phone" placeholder="手机号" type="number" v-model="form.phone" />
        <div class="desc">推广可以通过手机+短信验证码登录</div>
        <button class="submit" @click="handlePromoterChange">{{ currentPromoter ? "修改" : "添加" }}</button>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getPromoters,
  createPromoter,
  editPromoter,
} from "../../apis/admin";
import { getPromotersOfOffice } from "../../apis/super-admin";
import { Toast } from "vant";

export default {
  data() {
    return {
      id: null,
      promoters: [],
      maskingVisible: false,
      form: {
        name: "",
        phone: "",
      },
      currentPromoter: null,
    };
  },
  computed: {
    isOnlyAdmin() {
      const roles = this.$store.getters.roles;
      return roles.includes("ROLE_admin") && !roles.includes("ROLE_super_admin");
    },

    isSuperAdmin() {
      const roles = this.$store.getters.roles;
      return roles.includes("ROLE_super_admin");
    },

    policeOffice() {
      return this.$store.state.policeOffice;
    },

    ifCert() {
      return this.policeOffice.ifCert;
    },

    // 总推广数
    totalAmount() {
      return this.promoters.reduce((total, promoter) => {
        return total + promoter.totalAmount;
      }, 0);
    },

    // 今日推广数
    todayAmount() {
      return this.promoters.reduce((total, promoter) => {
        return total + promoter.todayAmount;
      }, 0);
    },

    // 总实名数
    totalAuth() {
      return this.promoters.reduce((total, promoter) => {
        return total + promoter.totalAuth;
      }, 0);
    },

    // 今日实名数
    todayAuth() {
      return this.promoters.reduce((total, promoter) => {
        return total + promoter.todayAuth;
      }, 0);
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.getPromoters();
  },
  methods: {
    // handleDownload() {
    //   const roles = this.$store.getters.roles;
    //   console.log("roles :>> ", roles);
    //   exportPromotersStats(this.id).then((res) => {
    //     window.open(res.data);
    //   });
    // },
    handleCustomersRoute(id) {
      this.$router.push({
        name: "Customers",
        query: { id },
      });
    },
    showMasking() {
      this.maskingVisible = true;
    },
    selectPromoter(promoter) {
      this.currentPromoter = promoter;
      this.maskingVisible = true;
      const form = {
        name: this.currentPromoter.name,
        phone: this.currentPromoter.phone,
      };
      this.form = form;
    },
    getHome() {
      this.$router.push({ name: "Home" });
    },
    closeMasking() {
      this.maskingVisible = false;
    },
    // 查询推广员
    getPromoters() {
      if (this.id) {
        getPromotersOfOffice(this.id).then((res) => {
          this.promoters = res.data;
        });
      } else {
        getPromoters().then((res) => {
          this.promoters = res.data;
          res.data.forEach((promoter) => {
            this.totalAmount = this.totalAmount + promoter.totalAmount;
            this.todayAmount = this.todayAmount + promoter.todayAmount;
          });
        });
      }
    },

    handlePromoterChange() {
      if (this.currentPromoter) {
        this.editPromoter();
      } else {
        this.createPromoter();
      }
    },
    //修改推广员
    editPromoter() {
      if (this.form.name == "") {
        Toast("请正确输入姓名");
        return;
      }

      if ((this.form.phone + "").length !== 11) {
        Toast("请正确输入手机号");
        return;
      }
      editPromoter(this.currentPromoter.id, this.form)
        .then((res) => {
          if (res.code === 104) {
            Toast("此手机号已被注册");
          } else {
            Toast("推广员编辑成功");
            this.getPromoters();
            this.closeMasking();
            this.form = {
              name: "",
              phone: "",
            };
          }
        })
        .catch(() => {
          Toast("推广员创建失败");
        });
    },
    // 创建推广员
    createPromoter() {
      if (this.form.name.length < 2) {
        Toast("请正确输入姓名");
        return;
      }

      if ((this.form.phone + "").length !== 11) {
        Toast("请正确输入手机号");
        return;
      }

      createPromoter(this.form)
        .then((res) => {
          if (res.code === 104) {
            Toast("此手机号已被注册");
          } else {
            Toast("推广员创建成功");
            this.getPromoters();
            this.closeMasking();
            this.form = {
              name: "",
              phone: "",
            };
          }
        })
        .catch(() => {
          Toast("推广员创建失败");
        });
    },
  },
  watch: {
    maskingVisible() {
      if (!this.maskingVisible) {
        this.currentPromoter = null;
        this.form = {
          name: "",
          phone: "",
        };
      }
    },
  },
  components: {
    // [Toast.name]: Toast
  },
  props: {},
};
</script>
<style lang="less" scoped>
.page {
  background-color: rgb(16 38 139);
  height: 100vh;
  background-image: url(../../assets/images/admin-bg.jpg);
  background-size: cover;
  padding: 25px;
  box-sizing: border-box;
  overflow-y: scroll;
}

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  .title {
    .label {
      width: 88px;
      height: 25px;
      display: block;
    }
  }
}

.promoter-label {
  height: 24px;
  width: 88px;
}

.plus {
  width: 25px;
  height: 25px;
}
.promoters {
  margin-top: 47px;
}
.promoter {
  color: #c2cff5;
  display: flex;
  border-bottom: 1px solid rgba(194, 207, 245, 0.37);
  padding: 15px 0;
  .info {
    width: 50%;
    .name {
      font-size: 15px;
      font-weight: bold;
      line-height: 150%;
    }
  }
  .count-total {
    width: 25%;
    text-align: center;
  }
  .count-today {
    width: 25%;
    text-align: center;
  }
  .number {
    font-size: 17px;
    color: #9dff77;
    font-weight: bold;
    line-height: 150%;
  }

  .label {
    font-size: 13px;
    line-height: 150%;
  }
}
.sum {
  .info {
    display: flex;
    align-items: center;
  }
}

// Dialog
.masking {
  height: 100vh;
  width: 100vw;
  background-color: rgba(30, 35, 58, 0.93);
  position: absolute;
  top: 0;
  left: 0;
}
.dialog {
  width: 315px;
  height: 237px;
  background-image: url(../../assets/images/dialog-bg.svg);
  background-size: 100% 100%;
  position: relative;
  left: calc(50% - 157.5px);
  top: calc(50% - 137.5px);
  padding: 25px;
  box-sizing: border-box;

  .close {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 10px;
    height: 10px;
  }
  .title {
    font-size: 17px;
    color: #c2cff5;
    text-align: center;
  }
  .form-input {
    width: 100%;
    height: 35px;
    margin-top: 15px;
    border: 1.5px solid #20a3f5;
    background-color: rgba(16, 26, 57, 0.5);
    color: #c2cff5;
    font-size: 13px;
    padding-left: 15px;
    box-sizing: border-box;
    line-height: 35px;
  }

  .desc {
    color: #c2cff5;
    font-size: 12px;
    text-align: center;
    margin-top: 10px;
  }

  .submit {
    width: 100%;
    height: 35px;
    background-color: #20a3f5;
    border: none;
    color: white;
    font-size: 16px;
    border-radius: 2px;
    margin-top: 10px;
  }
}

.empty {
  font-size: 18px;
  color: #c2cff5;
  text-align: center;
  margin: 50px 0;
}
</style>