<template>
  <div class="error">
    <img src="@/assets/images/error.svg" class="icon" alt="" />
    <div class="text-wrap">
      <div class="text">二维码已过期</div>
      <div class="text">请尝试获取新的二维码</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang='less' scoped>
.error {
  width: 100vw;
  height: 100vh;
  text-align: center;

  .icon {
    margin: 50px auto 0;
    width: 80px;
    height: 80px;
    display: block;
  }
  .text-wrap {
    margin-top: 34px;
    .text {
      color: #333;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 8px;
    }
  }
}
</style>