<template>
  <div class="home">
    <div class="header">
      <img src="@/assets/images/title.png" alt class="title" />
    </div>
    <div class="body">
      <div class="label">推广员登录</div>
      <div class="form">
        <div class="input-wrap">
          <input
            type="number"
            placeholder="手机号"
            v-model="phone"
            class="phone input"
            @blur="validPhone"
          />
          <div class="error" v-if="!phoneValid">手机号码填写不正确</div>
        </div>
        <div class="input-wrap">
          <input
            type="number"
            placeholder="验证码"
            v-model="code"
            class="code input"
            @blur="validCode"
          />
          <div class="action" @click="getCode" v-if="!lock">获取验证码</div>
          <div class="action" v-else>{{ count }}S后可重新获取</div>
          <div class="error" v-if="!codeValid">未填写验证码</div>
        </div>
        <div class="btn-wrap">
          <div class="btn" @click="userLogin">登录</div>
        </div>
      </div>
    </div>
    <div class="footer">
      <img src="@/assets/images/icons.png" alt class="icons" />
      <!-- <div class="text">温州市公安局</div> -->
    </div>
  </div>
</template>

<script>
const reg_tel =
  /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
import { sendVerify, login } from "@/apis/login";
import { Toast } from "vant";
export default {
  name: "Login",
  components: {},
  data() {
    return {
      phone: "",
      phoneValid: true,
      codeValid: true,
      code: "",
      lock: false,
      count: 60,
    };
  },
  methods: {
    validCode() {
      if (this.code === "") {
        this.codeValid = false;
      } else {
        this.codeValid = true;
      }
    },
    validPhone() {
      if (!reg_tel.test(this.phone)) {
        this.phoneValid = false;
      } else {
        this.phoneValid = true;
      }
    },
    getCode() {
      if (this.phone === "") {
        this.phoneValid = false;
        return;
      }
      if (!this.phoneValid || this.lock) return;
      let params = {
        phone: this.phone,
      };
      sendVerify(params).then(() => {
        this.lock = true;
        this.countDown();
      });
    },
    countDown() {
      setInterval(() => {
        if (this.count === 0) {
          clearInterval();
          this.lock = false;
          this.count = 60;
        }
        let count = this.count;
        count--;
        this.count = count;
      }, 1000);
    },
    userLogin() {
      this.validPhone();
      this.validCode();
      if (this.phoneValid && this.codeValid) {
        let params = {
          phone: this.phone,
          code: this.code,
        };
        login(params).then((res) => {
          if (res.code == 200) {
            const roles = res.data.roleList;
            this.$store.commit("setAccessToken", res.data.token);
            this.$store.commit("setRoles", roles);
            const policeOffice = {
              district: res.data.district,
              ifCert: res.data.ifCert,
            };
            this.$store.commit("setPoliceOffice", policeOffice);

            if (roles.includes("ROLE_super_admin")) {
              this.$router.push({
                name: "Offices",
              });
            } else if (roles.includes("ROLE_admin")) {
              this.$router.push({
                name: "Promoters",
              });
            } else {
              const id = res.data.promoter_id;
              this.$router.push({ name: "Home", query: { id } });
            }
          } else {
            Toast(res.msg);
          }
        });
      }
    },
  },
};
</script>
<style lang='less' scoped>
.home {
  text-align: center;
  width: 100vw;
  height: 100vh;
  background: url("../assets/images/bg.jpg");
  background-position: center;
  background-size: cover;
  position: relative;
  .header {
    position: relative;
    .title {
      margin-top: 15px;
      width: 283px;
      height: 150px;
      object-fit: cover;
    }
  }
  .body {
    width: 100%;
    margin-top: 25px;
    .label {
      text-align: left;
      padding-left: 46px;
      font-size: 16px;
      font-weight: 500;
      color: #c2cff5;
    }
    .form {
      margin-top: 25px;
      padding: 0 46px;
      .input-wrap {
        margin-bottom: 15px;
        position: relative;
        .input {
          font-size: 13px;
          color: #fff;
          background: rgba(16, 26, 57, 0.5);
          border: 1px solid #20a3f5;
          padding: 0 15px;
          width: calc(100% - 32px);
          height: 35px;
          outline: none;
        }
        .action {
          cursor: pointer;
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
          color: #37d7ff;
          font-size: 13px;
        }
        .error {
          position: absolute;
          left: 0;
          color: #cd2020;
          font-size: 12px;
        }
      }

      .btn-wrap {
        .btn {
          width: 100%;
          height: 35px;
          background: #20a3f5;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }
  .footer {
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    bottom: 15px;
    .icons {
      margin: 0 auto;
      height: 35px;
      width: 258px;
      display: block;
    }
    .text {
      margin-top: 15px;
      font-weight: bold;
      color: #c2cff5;
      font-size: 13px;
    }
  }
}
::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #c2cff5;
  font-size: 13px;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #c2cff5;
  font-size: 13px;
}

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #c2cff5;
  font-size: 13px;
}
</style>
