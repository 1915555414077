import request from './request';
export function sendVerify(params) {
    return request({
        url: '/auth/verify_code',
        method: 'get',
        params
    })
}

export function login(params) {
    return request({
        url: '/auth/login',
        method: 'post',
        params
    })
}

export function customerSendVerify(params) {
    return request({
        url: '/customers/verify_code',
        method: 'get',
        params
    })
}