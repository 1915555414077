<template>
  <div class="home">
    <div class="body">
      <!-- <div class="label"> -->
      <!-- <img src="@/assets/images/police.png" alt class="avatar" /> -->
      <!-- </div> -->
      <div class="step">
        <h3 class="text">第一步：下载安装并实名认证</h3>
        <div class="subtitle">地址选择"浙江省温州市文成县”</div>
        <div>
          <van-button type="primary" @click="download">前往下载</van-button>
        </div>
      </div>

      <div class="step" style="padding: 30px">
        <div class="text">第二步：请上传实名认证后的个人信息页</div>
        <div class="subtitle">必须已经实名，并且地区为"浙江省温州市文成县”</div>
        <van-uploader v-model="fileList" :after-read="afterRead"></van-uploader>
      </div>
      <div class="step">
        <div class="step-wrap">
          <div class="step-title">操作步骤</div>
          <div class="step-text">①选择“我的”页面，点击上方“查看个人信息”</div>
          <div class="step-text">
            ②填写实名内容后，
            <b>截图“个人信息页面”并上传。</b>
            <br />
            <i @click="howTo">了解如何截图?</i>
          </div>
        </div>
        <div class="img-wrap">
          <img src="@/assets/images/step2.jpg" alt class="step-img" />
          <img src="@/assets/images/step1.jpg" alt class="step-img" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Button, Uploader, Dialog, Notify } from "vant";
import { upload } from "@/utils/upload.js";
import { updateAuth } from "../apis/customer";

export default {
  name: "",
  components: {
    [Button.name]: Button,
    [Uploader.name]: Uploader,
  },
  data() {
    return {
      fileList: [],
    };
  },
  computed: {
    form() {
      return this.$store.getters.form;
    },
  },
  created() {
    const url = this.$store.getters.url;
    if (url) {
      this.fileList[0] = { url: url };
    }
  },
  methods: {
    howTo() {
      Dialog({
        title: "手机如何截图？",
        message: '全面屏手机为同时按下电源键与音量升高键；老款iPhone为Home键与音量升高键；'
      });
    },
    afterRead(file) {
      upload(file).then((res) => {
        this.$store.commit("setUrl", res.url);
        this.fileList[0] = {
          url: res.url,
        };

        // 更新认证信息
        updateAuth({
          phone: this.form.phone,
          uri: res.url,
        })
          .then((res) => {
            console.log(res);
            const message = res.msg;
            Notify({
              type: message == '实名认证成功' ? 'success' : 'danger',
              message: message == '实名认证成功' ? message : '实名认证失败。请检查截图是否正确',
              duration: 5000,
            });
          })
          .catch(() => {
            Notify({
              type: 'danger',
              message: '图片上传失败。',
              duration: 5000,
            });
          })
      });
    },
    download() {
      window.location.href =
        "https://a.app.qq.com/o/simple.jsp?pkgname=com.hicorenational.antifraud";
    },
  },
};
</script>

<style lang='less' scoped>
.home {
  padding-top: 25px;
  text-align: center;
  width: 100vw;
  height: 100vh;
  background: url("../assets/images/bg.jpg");
  background-position: center;
  background-size: cover;
  position: relative;
  .header {
    position: relative;
    .title {
      margin-top: 15px;
      width: 283px;
      height: 150px;
      object-fit: cover;
    }
  }
  .body {
    width: 100%;
    height: 100vh;
    .step {
      .text {
        font-size: 16px;

        font-weight: bold;
        color: #fff;
        line-height: 150%;
        margin-block-start: 0em;
        margin-block-end: 0em;
      }
      .subtitle {
        font-size: 12px;
        line-height: 150%;
        color: #9dff77;
        margin-bottom: 20px;
      }
      .step-wrap {
        color: #fff;
        .step-title {
          color: #c2cff5;
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 10px;
        }
        .step-text {
          color: #c2cff5;
          font-size: 12px;
        }
      }
      .img-wrap {
        position: relative;
        z-index: 10;
        padding: 0 50px;
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
        .step-img {
          width: 107px;
          height: 190px;
        }
      }

      .upload-success {
        color: #9dff77;
      }
    }
  }
  .footer {
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    bottom: 15px;
    .icons {
      margin: 0 auto;
      height: 35px;
      width: 258px;
      display: block;
    }
    .text {
      margin-top: 15px;
      font-weight: bold;
      color: #c2cff5;
      font-size: 13px;
    }
  }
}
::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #c2cff5;
  font-size: 13px;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #c2cff5;
  font-size: 13px;
}

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #c2cff5;
  font-size: 13px;
}

////// Uploader
.uploader {
  padding: 20px;
  h3 {
    font-size: 16px;
  }
}
.van-uploader {
  width: 200px;
  height: 130px;
  background: rgba(16, 26, 57, 0.5);
}
/deep/.van-uploader__wrapper {
  width: 100%;
  height: 100%;
}
/deep/.van-uploader__upload {
  width: 100%;
  height: 100%;
  position: absolute;
}
/deep/.van-uploader__preview {
  margin: 0;
  width: 100%;
  height: 100%;
  background: rgba(16, 26, 57, 0.5);
}
/deep/.van-image.van-uploader.preview-image {
  width: 100%;
  height: 100%;
}
/deep/.van-uploader__preview-image {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
}
/deep/.van-uploader__preview-delete {
  z-index: 4;
}
</style>