<template>
  <div>
      hello list   
  </div>
</template>

<script>
export default {
  name: '',
components:{},
  data () {
   return {
   }
  },
  methods:{
  }
}
</script>

<style lang='less' scoped>

</style>