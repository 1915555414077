import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import List from "../views/List.vue";
import Promoters from "../views/admin/Promoters.vue";
import Offices from "../views/admin/Offices.vue";
import Error from "../views/Error.vue";
import Customers from "../views/Customers.vue";
import Certification from "../views/Certification.vue";
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/list",
    name: "List",
    component: List,
  },
  {
    path: "/admin/promoters",
    name: "Promoters",
    component: Promoters,
  },
  {
    path: "/admin/offices",
    name: "Offices",
    component: Offices,
  },
  {
    path: "/admin/customers",
    name: "Customers",
    component: Customers,
  },
  {
    path: "/certification",
    name: "Certification",
    component: Certification,
  },
  {
    path: "/error",
    name: "Error",
    component: Error,
  },
];
const router = createRouter({
  history: createWebHistory("/"),
  routes,
});

export default router;
