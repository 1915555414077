import axios from "axios";
import store from "@/store";
import router from '@/router';
const service = axios.create({
  baseURL: process.env.VUE_APP_API_PREFIX,
  timeout: 10000,
});

service.interceptors.request.use((config) => {
  const token = store.getters.accessToken

  config.headers["Authorization"] = token;
  return config;
});

service.interceptors.response.use(
  function(response) {
    return response.data;
  },
  function(error) {
    // console.log('error.response.data.message :>> ', error.response.data);
      if (error.response.status === 403) {//token过期重新登录
            router.push({ name: "Login" });
        }
    return Promise.reject(error);
  }
);

export default service;
