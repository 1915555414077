<template>
  <div class="page">
    <div class="head">
      <div class="title">
        <img src="@/assets/images/customer-label.png" alt class="label" />
      </div>
      <div class="icon-wrap">
        <img
          v-if="ifCert"
          style="margin-left: 25px"
          class="plus"
          src="../assets/images/plus.svg"
          @click="createCustomer"
        />
      </div>
    </div>
    <div class="customers">
      <div class="customer sum">
        <div class="info">
          <div class="name">
            <span>汇总</span>
          </div>
          <!-- <div class="label">{{ office.phone }}</div> -->
        </div>
        <div class="count">
          <div class="number">{{ totalLoad }}</div>
          <div class="label">下载总计</div>
        </div>
        <div class="count" v-if="ifCert">
          <div class="number">{{ totalRealName }}</div>
          <div class="label">实名总计</div>
        </div>
      </div>
      <div class="customer" v-for="customer in customers" :key="customer.id">
        <div class="info">
          <div class="name">
            <span>{{ customer.name }}</span>
            <span v-if="customer.state === 2" @click.stop="editCustomer(customer)">
              <img
                src="@/assets/images/edit.svg"
                style="width: 12px; height: 12px; padding-left: 10px"
                alt
              />
            </span>
          </div>
          <div class="label">{{ hidePhone(customer.phone) }}</div>
        </div>
        <div class="state">
          <div class="number green">已下载</div>
        </div>
        <div class="state" v-if="ifCert">
          <div class="number grey" v-if="customer.state === 0">未实名</div>
          <div class="number green" v-else-if="customer.state === 1">已实名</div>
          <div class="number" @click="imagePreview(customer.uri)" v-else>
            <span class="red">实名失败</span>
            <div style="font-size: 10px;">查看原因</div>
          </div>
        </div>
      </div>
    </div>
    <div class="masking" v-show="maskingVisible">
      <div class="dialog" @click.stop>
        <img class="close" src="../assets/images/close.svg" @click="closeMasking" />
        <div class="title">人员{{ isCreate ? "补录" : "修改" }}</div>
        <input
          class="form-input"
          name="name"
          placeholder="姓名"
          v-model="form.name"
          :disabled="!isCreate"
        />
        <input
          class="form-input"
          name="phone"
          placeholder="手机号"
          type="number"
          v-model="form.phone"
          v-if="isCreate"
        />
        <div class="input-wrap" v-if="isCreate">
          <input class="form-input" name="code" placeholder="验证码" type="number" v-model="form.code" />
          <div class="action" @click="getCode" v-if="!lock">获取验证码</div>
          <div class="action" v-else>{{ count }}S后可重新获取</div>
        </div>
        <div class="upload-wrap">
          <van-uploader v-model="fileList" :after-read="afterRead"></van-uploader>
          <div class="text">上传实名认证后个人信息截图</div>
        </div>
        <div style="color: white; text-align: center; font-size: 12px;">说明：仅支持截图修改</div>
        <button class="submit" @click="submit">{{ isCreate ? "补录" : "修改" }}</button>
      </div>
    </div>
  </div>
</template>
<script>
// import { getPoliceOffices } from "../apis/super-admin";
import { Toast, Uploader, ImagePreview, Notify } from "vant";
import { getCustomers, sendVerify, updateAuth } from "../apis/customer";
import { upload } from "@/utils/upload.js";
import { addRecord } from "../apis/customer";
export default {
  data() {
    return {
      offices: [],
      customers: [],
      maskingVisible: false,
      form: {
        name: "",
        phone: "",
        code: "",
        uri: "",
      },
      isCreate: true, // 是补录还是修改
      lock: false,
      count: 60,
      fileList: [],
    };
  },
  computed: {
    totalLoad() {
      return this.customers.length
    },
    totalRealName() {
      return this.customers.filter(item => item.state === 1).length
    },
    policeOffice() {
      return this.$store.state.policeOffice;
    },
    ifCert() {
      return this.policeOffice.ifCert;
    },
  },
  created() {
    // this.getPoliceOffices();
    this.getCustomers();
  },
  methods: {
    /**
    * 创建 Customer
    */
    createCustomer() {
      this.maskingVisible = true;
      this.isCreate = true
      this.form = {
        name: "",
        phone: "",
        code: "",
        uri: "",
      }
    },

    /**
     * 编辑 Customer
     */
    editCustomer(customer) {
      this.maskingVisible = true;
      this.isCreate = false
      this.form = {
        name: customer.name,
        phone: customer.phone,
        code: "",
        uri: customer.uri,
      }
    },


    submit() {
      if (this.isCreate) {
        this.submitCreateCustomer()
      } else {
        this.submitUpdateCustomer()
      }
    },

    // 提交创建推广对象
    submitCreateCustomer() {
      if (this.form.name === "") {
        Toast("请正确输入姓名");
        return;
      }
      if ((this.form.phone + "").length !== 11) {
        Toast("请正确输入手机号");
        return;
      }
      if (this.form.code === "") {
        Toast("请正确输入验证码");
        return;
      }
      if (this.form.uri === "") {
        Toast("请上传实名认证后个人信息截图");
        return;
      }
      addRecord(this.form).then((res) => {
        if (res.code == 200) {
          this.maskingVisible = false;
          this.getCustomers();
          Toast("补录成功");
        }
        Toast(`${res.msg}`);
      });
    },

    // 提交修改推广对象
    submitUpdateCustomer() {
      updateAuth({
        phone: this.form.phone,
        uri: this.form.uri,
      }).then(res => {
        const message = res.msg
        const code = res.code
        if (code === 200) {
          this.maskingVisible = false;
          this.getCustomers();
          Toast(message);
        }
      }).catch((error) => {
        console.log(error)
        Toast("截图上传失败")
      })
    },

    afterRead(file) {
      upload(file).then((res) => {
        this.form.uri = res.url;
        this.fileList[0] = {
          url: res.url,
        };
      });
    },
    getCode() {
      if (this.form.phone === "") {
        Toast("请填写手机号码");
        return;
      }
      let params = {
        phone: this.form.phone,
      };
      sendVerify(params).then(() => {
        this.lock = true;
        this.countDown();
      });
    },
    countDown() {
      setInterval(() => {
        if (this.count === 0) {
          clearInterval();
          this.lock = false;
          this.count = 60;
        }
        let count = this.count;
        count--;
        this.count = count;
      }, 1000);
    },




    closeMasking() {
      this.maskingVisible = false;
    },

    getCustomers() {
      const route = this.$route;
      const id = route.query.id;
      getCustomers({ userId: id }).then((res) => {
        this.customers = res.data;
      });
    },
    /**
     * 错误图片预览
     */
    imagePreview(url) {
      ImagePreview({
        images: [url], closeable: true,
      });


      Notify({
        type: 'warning',
        message: '必须提供"实名后的个人信息页面"，且地区为"文成县”。如果系统误判，请联系管理员。',
        duration: 5000,
      });
    },

    // 隐藏 phone
    hidePhone(phone) {
      return phone.replace(/(\d{3})\d{5}(\d{3})/, "$1****$2");
    },
  },
  components: {
    [Uploader.name]: Uploader,
  },
  props: {},
};
</script>
<style lang="less" scoped>
.page {
  background-color: rgb(16 38 139);
  height: 100vh;
  background-image: url(../assets/images/admin-bg.jpg);
  background-size: cover;
  padding: 25px;
  box-sizing: border-box;
  overflow-y: scroll;
}

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  .title {
    .label {
      height: 25px;
      width: 120px;
      display: block;
    }
  }
}

.promoter-label {
  height: 24px;
  width: 88px;
}

.plus {
  width: 25px;
  height: 25px;
}
.customers {
  margin-top: 47px;
}
.customer {
  color: #c2cff5;
  display: flex;
  border-bottom: 1px solid rgba(194, 207, 245, 0.37);
  padding: 15px 0;
  .info {
    width: 50%;
    .name {
      font-size: 18px;
      font-weight: bold;
      line-height: 150%;
    }
  }
  .count,
  .state {
    width: 25%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .count {
    .number {
      font-size: 17px;
      color: #9dff77;
      font-weight: bold;
      line-height: 150%;
    }
    .label {
      font-size: 13px;
      line-height: 150%;
    }
  }

  .state .number {
    font-size: 14px;
    &.grey {
      color: #c2cff5;
    }

    &.green {
      color: #9dff77;
    }

    &.red,
    .red {
      color: red;
    }
  }
}
.sum {
  .info {
    display: flex;
    align-items: center;
  }
}

// Dialog
.masking {
  height: 100vh;
  width: 100vw;
  background-color: rgba(30, 35, 58, 0.93);
  position: absolute;
  top: 0;
  left: 0;
}
.dialog {
  width: 315px;

  background-image: url(../assets/images/dialog-bg.png);
  background-size: 100% 100%;
  position: relative;
  left: calc(50% - 157.5px);
  top: calc(50%);
  transform: translateY(-50%);
  padding: 25px;
  box-sizing: border-box;
  .close {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 10px;
    height: 10px;
  }
  .title {
    font-size: 17px;
    color: #c2cff5;
    text-align: center;
  }
  .input-wrap {
    position: relative;
    .action {
      font-size: 13px;
      color: #fff;
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-5%);
    }
  }
  .form-input {
    width: 100%;
    height: 35px;
    margin-top: 15px;
    border: 1.5px solid #20a3f5;
    background-color: rgba(16, 26, 57, 0.5);
    color: #c2cff5;
    font-size: 13px;
    padding-left: 15px;
    box-sizing: border-box;
    line-height: 35px;
  }

  .desc {
    color: #c2cff5;
    font-size: 12px;
    text-align: center;
    margin-top: 10px;
  }

  .submit {
    width: 100%;
    height: 35px;
    background-color: #20a3f5;
    border: none;
    color: white;
    font-size: 16px;
    border-radius: 2px;
    margin-top: 10px;
  }
}
.upload-wrap {
  position: relative;
  .text {
    color: #fff;
    font-size: 12px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 36px;
    white-space: nowrap;
  }
}
.van-uploader {
  border: 1.5px solid #20a3f5;
  margin-top: 15px;
  width: calc(100% - 3px);
  height: 100px;
  margin-bottom: 20px;
  background: rgba(16, 26, 57, 0.5);
}
/deep/.van-uploader__wrapper {
  width: 100%;
  height: 100%;
}
/deep/.van-uploader__upload {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(16, 26, 57, 0);
}
/deep/.van-uploader__preview {
  margin: 0;
  width: 100%;
  height: 100%;
  background: rgba(16, 26, 57, 0.5);
}
/deep/.van-image.van-uploader.preview-image {
  width: 100%;
  height: 100%;
}
/deep/.van-uploader__preview-image {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
}
/deep/.van-uploader__preview-delete {
  z-index: 4;
}
</style>