<template>
  <div class="home">
    <div class="header">
      <img src="@/assets/images/title.png" alt class="title" />
    </div>
    <div class="body">
      <div class="label">
        <img src="@/assets/images/police.png" alt class="avatar" />
        <div class="text">注册时请选择“温州市{{ policeOffice ? policeOffice.district : "" }}”</div>
      </div>
      <div class="form">
        <div class="input-wrap">
          <input type="text" placeholder="姓名" v-model="name" class="name input" @blur="validName" />
          <div class="error" v-if="!nameValid">姓名未填写</div>
        </div>
        <div class="input-wrap">
          <input
            type="number"
            placeholder="手机号"
            v-model="phone"
            class="phone input"
            @blur="validPhone"
          />
          <div class="error" v-if="!phoneValid">手机号码填写不正确</div>
        </div>
        <div class="input-wrap">
          <input
            type="number"
            placeholder="验证码"
            v-model="code"
            class="code input"
            @blur="validCode"
          />
          <div class="action" @click="getCode" v-if="!lock">获取验证码</div>
          <div class="action" v-else>{{ count }}S后可重新获取</div>
          <div class="error" v-if="!codeValid">未填写验证码</div>
        </div>
        <div class="btn-wrap">
          <div class="btn" @click="submit">下一步</div>
        </div>
      </div>
    </div>
    <div class="footer">
      <img src="@/assets/images/icons.png" alt class="icons" />
      <div class="text">{{ policeOfficeName }}</div>
    </div>
  </div>
</template>

<script>
const reg_tel =
  /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
import { addCustomers } from "@/apis/promoter";
import { customerSendVerify } from "@/apis/login";
import { getUser } from "../apis/admin";
import { Toast } from "vant";
export default {
  name: "Login",
  components: {},
  data() {
    return {
      name: "",
      phone: "",
      code: "",
      nameValid: true,
      phoneValid: true,
      codeValid: true,
      count: 60,
      lock: false,
      policeOffice: null,
    };
  },
  computed: {
    policeOfficeName() {
      const policeOffice = this.policeOffice;
      if (!policeOffice) return "温州市公安分局";
      const { district } = policeOffice;
      if (!district) return "温州市公安分局";
      if (district.indexOf('县')) {
        return district + "公安局";
      } else {
        return "温州市公安局" + district.substring(0, district.length - 1) + "分局"
      }
    },
  },
  created() {
    this.getUser();
  },
  methods: {
    getUser() {
      getUser(this.$route.query.id).then((res) => {
        this.policeOffice = res.data.policeOffice;
        const isRegistered = this.$store.getters.isRegistered;
        if (isRegistered) {
          this.afterRegister();
        }
      });
    },
    // 处理注册成功后的操作
    afterRegister() {
      if (this.policeOffice.ifCert) {
        this.$router.push({ name: "Certification" });
      } else {
        window.location.href =
          "https://a.app.qq.com/o/simple.jsp?pkgname=com.hicorenational.antifraud";
      }
    },
    validName() {
      if (this.name === "") {
        this.nameValid = false;
      } else {
        this.nameValid = true;
      }
    },
    validPhone() {
      if (!reg_tel.test(this.phone)) {
        this.phoneValid = false;
      } else {
        this.phoneValid = true;
      }
    },
    validCode() {
      if (this.code === "") {
        this.codeValid = false;
      } else {
        this.codeValid = true;
      }
    },
    getCode() {
      if (this.phone === "") {
        this.phoneValid = false;
        return;
      }
      if (!this.phoneValid || this.lock) return;
      let params = {
        phone: this.phone,
      };
      customerSendVerify(params).then(() => {
        this.lock = true;
        this.countDown();
      });
    },
    countDown() {
      setInterval(() => {
        if (this.count === 0) {
          clearInterval();
          this.lock = false;
          this.count = 60;
        }
        let count = this.count;
        count--;
        this.count = count;
      }, 1000);
    },
    submit() {
      this.validName();
      this.validPhone();
      this.validCode();
      if (this.nameValid && this.phoneValid && this.codeValid) {
        const route = this.$route;
        let data = {
          code: this.code,
          name: this.name,
          phone: this.phone,
          userId: parseInt(route.query.id),
        };
        addCustomers(data).then((res) => {
          if (res.code === 200) {
            this.$store.commit("setRegister", true);
            this.$store.commit("setForm", {
              name: this.name,
              phone: this.phone,
            });
            this.afterRegister();
            return
          } else {
            Toast("出错啦: " + res.msg);
            return
          }
        });
      }
    },
  },
};
</script>
<style lang='less' scoped>
.home {
  text-align: center;
  width: 100vw;
  height: 100vh;
  background: url("../assets/images/bg.jpg");
  background-position: center;
  background-size: cover;
  position: relative;
  .header {
    position: relative;
    .title {
      margin-top: 15px;
      width: 283px;
      height: 150px;
      object-fit: cover;
    }
  }
  .body {
    width: 100%;
    margin-top: 25px;
    .label {
      display: flex;
      justify-content: center;
      align-items: center;
      .avatar {
        margin-right: 8px;
        width: 24px;
        height: 24px;
        display: block;
      }
      .text {
        font-size: 12px;
        font-weight: 500;
        color: #cd2020;
      }
    }
    .form {
      margin-top: 25px;
      padding: 0 46px;
      .input-wrap {
        margin-bottom: 15px;
        position: relative;
        .input {
          font-size: 13px;
          color: #fff;
          background: rgba(16, 26, 57, 0.5);
          border: 1px solid #20a3f5;
          padding: 0 15px;
          width: calc(100% - 32px);
          height: 35px;
          outline: none;
        }
        .error {
          position: absolute;
          color: #cd2020;
          font-size: 12px;
        }
        .action {
          cursor: pointer;
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
          color: #37d7ff;
          font-size: 13px;
        }
      }
      .btn-wrap {
        .btn {
          width: 100%;
          height: 35px;
          background: #20a3f5;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }
  .footer {
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    bottom: 15px;
    .icons {
      margin: 0 auto;
      height: 35px;
      width: 258px;
      display: block;
    }
    .text {
      margin-top: 15px;
      font-weight: bold;
      color: #c2cff5;
      font-size: 13px;
    }
  }
}
::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #c2cff5;
  font-size: 13px;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #c2cff5;
  font-size: 13px;
}

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #c2cff5;
  font-size: 13px;
}
</style>
